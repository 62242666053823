import React from 'react'
import Grid, { Cell } from './Grid'

const PageContent = ({ children }) => (
  <div className="page-content">
    <Grid>
      <Cell className="mdc-layout-grid__cell--span-9-desktop">{children}</Cell>
    </Grid>
  </div>
)

export default PageContent
