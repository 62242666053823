import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { urlFor } from './LinkI18n'
import PageLayout from './layout'
import MarkdownBlockFromTranslation from './MarkdownBlock'
import PageContent from './PageContent'
import PageSummary from './PageSummary'
import Block from './Block'

const DefaultPageBase = ({ intl, code, children }) => {
  const title = intl.formatMessage({ id: `pages.${code}.title` })
  return (
    <PageLayout
      title={title}
      pageSummary={
        <PageSummary
          title={title}
          lead={<FormattedMessage id={`pages.${code}.lead`} />}
        />
      }
      pageClassName={`page--${code}`}
    >
      <PageContent>{children}</PageContent>
    </PageLayout>
  )
}

const DefaultPage = injectIntl(DefaultPageBase)

export const DefaultPageWithContent = ({ code, ...props }) => (
  <DefaultPage code={code} {...props}>
    <MarkdownBlockFromTranslation
      className="page__description"
      id={`pages.${code}.description`}
    />
  </DefaultPage>
)

export const BlockForPageBase = ({intl, code, link}) => (
  <Block
    title={<FormattedMessage id={`pages.${code}.title`} />}
    link={urlFor(intl.locale, link)}
  >
    <PageContent>
      <MarkdownBlockFromTranslation
        className="page__description"
        id={`pages.${code}.lead`}
      />
    </PageContent>
  </Block>
)

export const BlockForPage = injectIntl(BlockForPageBase)
export default DefaultPage
